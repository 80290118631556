.loading-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(#031a2b, #031a2b);
}

.loader {
  width: 150px;
  pointer-events: none;
}
